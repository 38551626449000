import { request } from 'lib/request';

// get all Activities
export function getAllProfessionsServices({ params, authRequire }: any) {
  const { page, size } = params as any;
  return request('/professions', {
    method: 'get',
    authRequire,
    params: { page: page + 1, size }
  });
}
