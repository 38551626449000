import React, { useState, useEffect } from 'react';
import { TFormProps, TIconsList } from 'types/index';
import { useFormHook } from 'hooks/useFormHook';
import { CircularProgress, Stack } from '@mui/material';
import { TextField, CustomButton, TextFieldSelect } from 'components/index';
import { RULES } from 'constants/index';
import {
  getEstadosServices,
  getMunicipioServices,
  getParroquiaServices
} from 'services/estados';
import { getTypeSites } from 'services/sites';
import { searchItemLocal } from 'utils/helpers';

const FormSites = ({ onSubmit, loadingService, rowData }: TFormProps) => {
  const dataUser = searchItemLocal('dataUser');
  const { data, isNewRecord } = rowData;

  const [typesOptions, setTypesOptions] = useState([]);
  const { control, errors, handleSubmit } = useFormHook(data);

  const [idEMP, setIdEMP] = useState<any>({
    estadoId: '',
    municipioId: ''
  });
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [parroquias, setParroquias] = useState([]);

  const tiposInstitucion = [
    {
      id: '0',
      name: 'Pública'
    },
    {
      id: '1',
      name: 'Privada'
    },
    {
      id: '2',
      name: 'Semi-Privada'
    }
  ];

  /*
  Nombre de la institucion
  Tipo de Centro.
  Tipo de institucion.
  Ojetivo pedagógico de la visita?
  Describir las actividades a desarrollar durante las visitas?
  Dirección?
  Estado --
  Municipio --
  Parroquia
  Teléfono Local
  Email?
  Esta Operativo actualmente
  Posee medidas de bioseguridad
  Posee baños en buen estado
  Posee Estacionamiento
  Posee servicio médico
  Posee Comedor
  Redes Sociales?
  Link coordenadas de Google Maps?
  */

  const getEstados = async () => {
    try {
      const res = await getEstadosServices({ params: {}, authRequire: true });

      setEstados(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeEstado = (e: any) => {
    const estadoId = e.target.value;
    if (estadoId) {
      setIdEMP({ ...idEMP, estadoId });
    }
  };

  const getMunicipio = async (estadoId: number) => {
    const res = await getMunicipioServices({
      params: { estadoId },
      authRequire: true
    });
    console.log(res.data);
    setMunicipios(res.data.data);
  };

  const handleChangeMunicipio = (e: any) => {
    const municipioId = e.target.value;
    if (municipioId) {
      setIdEMP((prevState: any) => ({ ...prevState, municipioId }));
    }
  };
  async function getEntities() {
    try {
      const { data } = await getTypeSites({ authRequire: true });
      setTypesOptions(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getEntities();
  }, []);

  const getParroquia = async (municipioId: any) => {
    try {
      console.count(municipioId);
      const res = await getParroquiaServices({
        params: { municipioId },
        authRequire: true
      });

      setParroquias(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(dataUser.userRole.roleId);

  useEffect(
    () => {
      getMunicipio(idEMP.estadoId);
    },
    [idEMP.estadoId]
  );

  useEffect(
    () => {
      getParroquia(idEMP.municipioId);
    },
    [idEMP.municipioId]
  );

  useEffect(() => {
    getEstados();
  }, []);

  const options = [
    {
      id: true,
      name: 'Si'
    },
    {
      id: false,
      name: 'No'
    }
  ];

  return (
    <Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          spacing={3}
          alignItems="center"
          maxWidth={550}>
          <TextField
            name="name"
            label="Nombre de la institución *"
            control={control}
            variant="standard"
            rules={RULES.required}
            error={Boolean(errors.name)}
            errmsg={errors.name}
          />

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextFieldSelect
              name="typeId"
              label="Tipo de Centro *"
              control={control}
              variant="standard"
              options={typesOptions}
              rules={RULES.required}
              error={Boolean(errors.typeId)}
              autoFocus={false}
            />

            <TextFieldSelect
              name="typeSite"
              label="Tipo de Institucion *"
              control={control}
              variant="standard"
              options={tiposInstitucion}
              rules={RULES.required}
              error={Boolean(errors.typeSite)}
            />
          </div>

          <TextField
            name="pedagogicalObjective"
            label="Objetivo pedagógico de la visita"
            control={control}
            variant="standard"
            rules={RULES.required}
            error={Boolean(errors.pedagogicalObjective)}
            errmsg={errors.pedagogicalObjective}
          />

          <TextField
            name="activities"
            label="Actividades a desarrollar"
            control={control}
            variant="standard"
            multiline={true}
            rules={RULES.required}
            error={Boolean(errors.activities)}
            errmsg={errors.activities}
          />

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextField
              style={{ flex: '1' }}
              name="direction"
              label="Dirección"
              control={control}
              variant="standard"
              multiline
              rules={RULES.required}
              error={Boolean(errors.direction)}
              errmsg={errors.direction}
            />
          </div>

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextFieldSelect
              name="estadoId"
              variant="standard"
              control={control}
              options={estados}
              label="Estados"
              itemLabel="nombre"
              disabled={dataUser.userRole.roleId !== 1 ? true : false}
              handleChange={handleChangeEstado}
              rules={RULES.required}
              error={Boolean(errors.estadoId)}
            />
          </div>

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextFieldSelect
              name="municipioId"
              variant="standard"
              control={control}
              options={municipios}
              label="Municipio"
              itemLabel="nombre"
              handleChange={handleChangeMunicipio}
              rules={RULES.required}
              error={Boolean(errors.municipioId)}
            />

            <TextFieldSelect
              name="parroquiaId"
              variant="standard"
              control={control}
              options={parroquias}
              label="Parroquia"
              itemLabel="nombre"
              rules={RULES.required}
              error={Boolean(errors.parroquiaId)}
            />
          </div>

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextField
              style={{ flex: '1' }}
              name="phone"
              label="Teléfono *"
              control={control}
              variant="standard"
              rules={RULES.required}
              error={Boolean(errors.phone)}
              errmsg={errors.phone}
            />

            <TextField
              style={{ flex: '1' }}
              name="email"
              label="Email"
              control={control}
              variant="standard"
              rules={RULES.required}
              error={Boolean(errors.email)}
              errmsg={errors.email}
            />
          </div>

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextFieldSelect
              style={{ flex: '1' }}
              name="active"
              label="¿Esta Operativo actualmente?"
              control={control}
              variant="standard"
              options={options}
              rules={RULES.required}
              error={Boolean(errors.active)}
            />

            <TextFieldSelect
              style={{ flex: '1' }}
              name="biosecurity"
              label="¿Posee medidas de bioseguridad?"
              control={control}
              variant="standard"
              options={options}
              rules={RULES.required}
              error={Boolean(errors.biosecurity)}
            />
          </div>

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextFieldSelect
              style={{ flex: '1' }}
              name="bathrooms"
              label="¿Posee baños en buen estado?"
              control={control}
              variant="standard"
              options={options}
              rules={RULES.required}
              error={Boolean(errors.bathrooms)}
            />

            <TextFieldSelect
              style={{ flex: '1' }}
              name="parking"
              label="¿Posee Estacionamiento?"
              control={control}
              variant="standard"
              options={options}
              rules={RULES.required}
              error={Boolean(errors.parking)}
            />
          </div>

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextFieldSelect
              style={{ flex: '1' }}
              name="medicalService"
              label="¿Posee servicio médico?"
              control={control}
              variant="standard"
              options={options}
              rules={RULES.required}
              error={Boolean(errors.medicalService)}
            />

            <TextFieldSelect
              style={{ flex: '1' }}
              name="diningRoom"
              label="¿Posee Comedor?"
              control={control}
              variant="standard"
              options={options}
              rules={RULES.required}
              error={Boolean(errors.diningRoom)}
            />
          </div>

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextField
              style={{ flex: '1' }}
              name="socialNetworks"
              label="Redes sociales"
              control={control}
              variant="standard"
              rules={RULES.required}
              error={Boolean(errors.socialNetworks)}
              errmsg={errors.socialNetworks}
            />

            <TextField
              style={{ flex: '1' }}
              name="googleMapLink"
              label="Link de google maps"
              control={control}
              variant="standard"
              rules={RULES.required}
              error={Boolean(errors.googleMapLink)}
              errmsg={errors.googleMapLink}
            />
          </div>

          {loadingService
            ? <CircularProgress />
            : <CustomButton
                label={isNewRecord ? 'Crear' : 'Actualizar'}
                typeVariant="outlined"
                typeAction="submit"
                disabled={loadingService}
              />}
        </Stack>
      </form>
    </Stack>
  );
};

export default FormSites;
