import React, { useMemo, useState, useEffect } from 'react';
import styles from 'styles/Layout.module.scss';
import { Stack, Typography } from '@mui/material';
import axios from 'axios'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getAllEntitiesServices } from 'services/entities';
import {
  ActionsButtonsTable,
  CustomButton,
  CardNormal,
  TableE,
  CustomModal,
  FormRutas,
  DialogConfirm,
  FormImportExcel
} from 'components/index';

import Bars from 'components/Charts/Bars';
import PieChartc from 'components/Charts/PieChartc';

import {
  getAllAcademicRoutesServices,
} from 'services/academicRoutes'
import { getAcademicRoutesFiltered } from 'services/filterData';
import { getAllActivitiesServices } from 'services/activities';

import { getEstadosServices } from 'services/estados';
import { downloadFile } from 'services/donwloadFile';
import { searchItemLocal } from 'utils/helpers';
import { generateReportServices } from 'services/report';
import { useAlert } from 'hooks/useAlert';



const Reports = () => {

  const [firstOption, setFirstOption] = useState("")
  const [secondOptions, setSecondOptions] = useState([])
  const [secondOption, setSecondOption] = useState()
  const [time, setTime] = useState("")
  const [activity, setActivity] = useState("")
  const [activities, setActivities] = useState([])
  const [filters, setFilters] = useState(undefined)
  const [report, setReport] = useState(undefined)
  /* const [thirdOption, setThirdOption] = useState("") */


  const { openAlert } = useAlert();
  const dataUser = searchItemLocal('dataUser')
  const [openModal, setOpenModal] = useState({
    modalCreate: false,
    modalImport: false,
    dialogDelete: false,
    modalDetails: false
  });

  /* inicializar los datos para el formulario */
  const initialDatarow = {
    id: '',
    name: '',
    siteId: undefined,
    enteId: dataUser.userRole.enteId,
    estadoId: dataUser.userRole.ente.estado.id,
    activityId: undefined,
    datePlanned: undefined,
    responsibleFirstName: '',
    responsibleLastName: '',
    responsibleIdentity: '',
    responsiblePhone: '',
    responsibleCargo: undefined,
    estudiantes: undefined,
    observations: '.',
    createdAt: undefined,
    createBy: undefined,
    fileExtension: undefined
  };


  const [loadingService, setLoadingService] = useState(false);

  const [rowData, setRowData] = useState({
    data: initialDatarow,
    isNewRecord: true
  });

  const formDataImport = {
    startDate: '',
    endDate: '',
    enteId: dataUser.userRole.enteId,
  }



  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 50
  });




  const downloadAcademicRoute = async (name, fileExtension) => {
    try {

      const lmao = axios.create({
        baseURL: 'http://localhost:3333',
      });

      lmao.interceptors.request.use(config => {
        config.responseType = 'blob'
        config.params = {
          // add your default ones
          timestamp: new Date().getTime(),
          name: name,
          fileExtension: fileExtension,
          // spread the request's params
          ...config.params,
        };
        return config;
      });

      lmao.get('/api/download').then((dat) => {
        const url = URL.createObjectURL(dat.data)

        const a = document.createElement('a')
        a.href = url
        a.download = `${name}${fileExtension}`
        /* a.download = `solo.xls` */
        document.body.appendChild(a)
        a.click()
        a.remove()
        URL.revokeObjectURL(url)
        console.log(url)
        console.log(a)
        localStorage.removeItem('download')
      })

    } catch (err) {
      console.log(err)
    }
  };


  const getAcademicRoutes = async (aja) => {
    try {
      setPageState((prev) => ({ ...prev, isLoading: true }));
      const { data } = await getAllAcademicRoutesServices({
        params: {
          skip: pageState?.page * pageState?.pageSize,
          take: pageState?.pageSize,
          filters: aja ? aja : filterOption ? filterOption : dataUser.filters ? dataUser.filters : "",
          deleted: false,
          enteId: dataUser.userRole.roleId === 1 ? null : dataUser.userRole.enteId,
        },
        authRequire: true,
      });


      setPageState((prev) => ({
        ...prev,
        isLoading: false,
        data: data?.data ? data?.data : [],
        total: data?.total,
      }));
    } catch (err) {
      console.log(err)
    }
  };

  const handleDeleteRow = (dataRow) => () => {
    setOpenModal({ dialogDelete: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };


  const handleViewRow = (dataRow) => () => {
    setOpenModal({ modalDetails: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };


  const onSubmitImport = async (data) => {

    try {
      setLoadingService(true);
      const res = await generateReportServices({
        params: data,
        authRequire: true,
      });
      openAlert(res.data.message)

      console.log(res)
    } catch (error) {
      openAlert('error a la hora de generar el reporte', { variant: 'error' })
      console.log(error)
    } finally {
      setLoadingService(false);
      setOpenModal({ modalImport: false });
    }

  }


  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 20 },
      {
        field: 'ente', headerName: 'Ente', width: 350, renderCell: params => {
          return <p>{params.value.name}</p>
        }
      },
      { field: 'name', headerName: 'Nombre de Ruta', width: 300 },
      { field: 'datePlanned', headerName: 'Fecha', width: 100 },
      {
        field: 'activity', headerName: 'Tipo de Actividad', width: 180, renderCell: params => {
          return params.value.name
        }
      },
      { field: 'createdAt', headerName: 'Fecha de creación', width: 140 },
      { field: 'createBy', headerName: 'creado por', width: 200 },
      {
        field: 'actions',
        headerName: 'Acciones',
        renderCell: params =>
          <>
            {
              dataUser.userRole.roleId > 4 ?
                <ActionsButtonsTable
                  /* handleEditRow={handleEditRow(params)} */
                  /* handleDeleteRow={handleDeleteRow(params)} */
                  handleShowDetails={handleViewRow(params)}
                />

                :
                <ActionsButtonsTable
                  /* handleEditRow={handleEditRow(params)} */
                  handleDeleteRow={handleDeleteRow(params)}
                  handleShowDetails={handleViewRow(params)}
                />
            }
          </>,
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true
      }
    ],
    []
  );

  useEffect(() => {

    const lmao = async () => {
      if (secondOption == null) {
        getAcademicRoutes();
      } else {
        const { data } = await getAcademicRoutesFiltered({
          params: {
            skip: pageState?.page * pageState?.pageSize,
            take: pageState?.pageSize,
            ...filters
          },
          authRequire: true,
        })

        setPageState((prev) => ({
          ...prev,
          isLoading: false,
          data: data?.data ? data?.data : [],
          total: data?.total,
        }));
        console.log(data)
      }
    }


    lmao()


  }, [pageState.pageSize, pageState.page]);

  useEffect(() => {
    const getActivities = async () => {
      const data = await getAllActivitiesServices({
        params: {
          skip: 0,
          take: 9999,
          deleted: false
        },
        authRequire: true
      });

      setActivities(data.data.data)
    }
    getActivities()
  }, []);


  async function getEntities() {
    try {
      const { data } = await getAllEntitiesServices({ authRequire: true });

      if (dataUser.userRole.roleId === 1) {
        setFilterOptions(data.data)
      }

      if (dataUser.filters) {
        let entitiesInCharge = dataUser.filters.split(",").map(Number)
        let aux = []

        data.data.forEach((element) => {
          if (entitiesInCharge.includes(element.id)) {
            aux.push({ id: element.id, acronim: element.acronim })
          }
        });

        setFilterOptions(aux)
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getEntities();
  }, []);

  const [filterOptions, setFilterOptions] = useState([])
  const [filterOption, setFilterOption] = useState('');

  const handleChange = async (event) => {
    setFilterOption(event.target.value);
    getAcademicRoutes(event.target.value);
  };

  //filter buttons handling
  const handleFirstButton = async (event) => {
    setFirstOption(event.target.value)
    setSecondOption(0)

    if (event.target.value == "Estados") {
      const res = await getEstadosServices({ lmao: "lmao" })
      setSecondOptions(res.data.data)
    } else {
      const { data } = await getAllEntitiesServices({ authRequire: true })
      setSecondOptions(data.data)
    }
  }

  const handleSecondButton = async (event) => {
    setSecondOption(event.target.value)

    /* if(event.target.value == "Estados"){
      const res = await getEstadosServices({lmao:"lmao"})
      setSecondOptions(res.data.data)
    } */
  }

  const handleReportSubmit = async (event) => {
    event.preventDefault()
    console.log("firstOption: " + firstOption + " secondOption:" + secondOption + " thirdOption:" + time)

    var filters = {}

    console.log(firstOption)
    if (firstOption == "Estados") {
      filters.estadoId = secondOption
    } else {
      filters.enteId = secondOption
    }

    if (activity !== "") {
      filters.activityId = activity
    }


    setFilters(filters)
    const { data } = await getAcademicRoutesFiltered({
      params: {
        skip: pageState?.page * pageState?.pageSize,
        take: pageState?.pageSize,
        ...filters
      },
      authRequire: true,
    })

    setReport(data.reports)

    setPageState((prev) => ({
      ...prev,
      isLoading: false,
      data: data?.data ? data?.data : [],
      total: data?.total,
    }));
    console.log(data)
  }

  return (
    <section>
      <div className={styles['modules__header']}>
        <Typography className={styles['page-title']} component="h1">
          Reportes
        </Typography>
      </div>
      <CardNormal>
        <Stack justifyContent={'flex-end'} gap={4}>
          <form onSubmit={handleReportSubmit} style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <div style={{ display: "flex", gap: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Seleccione Ente o Estado</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="target"
                  value={firstOption}
                  label="Seleccione Ente o Estado"
                  onChange={handleFirstButton}
                >
                  <MenuItem value='Entes' >Entes</MenuItem>
                  <MenuItem value='Estados' >Estados</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label2">Especifique</InputLabel>
                <Select
                  labelId="demo-simple-select-label2"
                  id="specification"
                  value={secondOption}
                  label="Especifique"
                  onChange={handleSecondButton}
                >
                  <MenuItem value={0} >TODOS</MenuItem>
                  {secondOptions.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id} >{firstOption == "Estados" ? item.nombre : item.acronim}</MenuItem>
                    )

                  })}

                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Seleccione tipo de actividad</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="target"
                  value={activity}
                  label="Seleccione Ente o Estado"
                  onChange={(event) => setActivity(event.target.value)}
                >
                  <MenuItem value={0} >TODOS</MenuItem>
                  {activities.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem>
                    )

                  })}
                </Select>
              </FormControl>

              <button>
                FILTRAR
              </button>
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Seleccione Tiempo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="time"
                  value={time}
                  label="Seleccione Tiempo"
                  onChange={(e) => setTime(e.target.value)}
                >
                  <MenuItem value="semana" >SEMANA</MenuItem>
                  <MenuItem value="mes" >MES</MenuItem>
                  <MenuItem value="año" >AÑO</MenuItem>

                </Select>
              </FormControl>


              <div style={{ display: "flex", gap: "10px" }}>
                <button>
                  ⬅️
                </button>
                lol
                <button>
                  ➡️
                </button>
              </div>
            </div>



          </form>

          <TableE
            pageState={pageState}
            setPageState={setPageState}
            columns={columns}
            handleRowClick={(params) => {
              setOpenModal({ details: true });
              setRowData({ data: params.row, isNewRecord: false });

            }}
          />

          <div>
            {report ?
              <div>
                <div>
                  Reportes:<br />

                  Cantidad de niñ@s atendidos: {report.totalKids}<br />
                  Cantidad de niñas atendidos: {report.totalFemale}<br />
                  Cantidad de niños atendidos: {report.totalMale}<br />
                  Cantidad de niñ@s sin genero reportado: {report.totalUndefined}<br />
                  Edad promedio de niñ@s atendidos: {report.ages}<br />
                  Cantidad de niños sin edades definidas: {report.totalagesUndefined}<br />
                  <br />
                  Lista de actividades Relizadas:<br />
                  {report.activitiesTotal.map((item) => <div>{item.name}: {item.total}</div>)}
                </div>
                <div style={{width: "100%", minHeight: "30dvh"}}>
                  {/* <PieChartc data={[{ name: "Female", value: report.totalFemale },
                                    { name: "Male", value: report.totalMale },]} /> */}

                  <Bars data={report.activitiesTotal} />
                </div>
              </div>
              : ""
            }
          </div>

        </Stack>
      </CardNormal>

      <CustomModal
        modalTitle='Exportar a Excel'
        openModal={openModal.modalImport}
        setOpenModal={setOpenModal}>
        <FormImportExcel
          onSubmit={onSubmitImport}
          rowData={formDataImport}
          loadingService={loadingService}
        />
      </CustomModal>

      <CustomModal
        modalTitle='Detalles'
        openModal={openModal.modalDetails}
        setOpenModal={setOpenModal}>
        <div>
          <h3>{rowData.data.name}</h3>
          fecha: {rowData.data.datePlanned} <br />
          fecha de creación: {rowData.data.createdAt} <br />
          Creado por: {rowData.data.createBy} <br />
          <button onClick={() => downloadAcademicRoute(rowData.data.name, rowData.data.fileExtension)}>
            Descargar Excel
          </button>
        </div>
      </CustomModal>

    </section>
  );
};

export default Reports;
