import React, { useEffect, useState } from 'react';
import { TFormProps, TIconsList } from 'types/index';
import { useFormHook } from 'hooks/useFormHook';
import { RULES } from 'constants/index';
import { CircularProgress, Stack, Grid } from '@mui/material';
import { TextField, CustomButton, TextFieldSelect } from 'components/index';
import {
  getEstadosServices,
  getMunicipioServices,
  getParroquiaServices
} from 'services/estados';
const FormSchools = ({ onSubmit, loadingService, rowData }: TFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data, isNewRecord } = rowData;
  const [idEMP, setIdEMP] = useState<any>({
    estadoId: data.estadoId,
    municipioId: data.municipioId
  });
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [parroquias, setParroquias] = useState([]);
  const { control, handleSubmit, errors } = useFormHook(data);

  const getEstados = async () => {
    try {
      const res = await getEstadosServices({ params: {}, authRequire: true });

      setEstados(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeEstado = (e: any) => {
    const estadoId = e.target.value;
    if (estadoId) {
      setIdEMP({ ...idEMP, estadoId });
    }
  };

  const getMunicipio = async (estadoId: number) => {
    const res = await getMunicipioServices({
      params: { estadoId },
      authRequire: true
    });
    setMunicipios(res.data.data);
  };

  const handleChangeMunicipio = (e: any) => {
    const municipioId = e.target.value;
    if (municipioId) {
      setIdEMP((prevState: any) => ({ ...prevState, municipioId }));
    }
  };

  const getParroquia = async (municipioId: any) => {
    try {
      const res = await getParroquiaServices({
        params: { municipioId },
        authRequire: true
      });

      setParroquias(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(
    () => {
      if (idEMP.estadoId) {
        getMunicipio(idEMP.estadoId);
      }
    },
    [idEMP.estadoId]
  );

  useEffect(
    () => {
      if (idEMP.municipioId) {
        getParroquia(idEMP.municipioId);
      }
    },
    [idEMP.municipioId]
  );

  useEffect(() => {
    getEstados();
  }, []);

  /*  */
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="column"
        spacing={3}
        justifyContent="center"
        maxWidth={550}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              label="Nombre"
              control={control}
              variant="standard"
              error={Boolean(errors.name)}
              errmsg={errors.name}
              rules={RULES.required}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="codPlantel"
              label="Codigo de la institución"
              control={control}
              variant="standard"
              error={Boolean(errors.codPlantel)}
              errmsg={errors.codPlantel}
              rules={RULES.required}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldSelect
              name="estadoId"
              variant="standard"
              control={control}
              options={estados}
              label="Estados"
              rules={RULES.required}
              error={Boolean(errors.estado)}
              itemLabel="nombre"
              handleChange={handleChangeEstado}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldSelect
              name="municipioId"
              variant="standard"
              control={control}
              options={municipios}
              label="Municipios"
              rules={RULES.required}
              error={Boolean(errors.municipio)}
              itemLabel="nombre"
              handleChange={handleChangeMunicipio}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldSelect
              name="parroquiaId"
              variant="standard"
              control={control}
              options={parroquias}
              label="Parroquias"
              rules={RULES.required}
              error={Boolean(errors.parroquia)}
              itemLabel="nombre"
              // handleChange={handleChangeEstado}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="phone"
              label="Telefono"
              variant="standard"
              control={control}
              rules={RULES.number}
              error={Boolean(errors.phone)}
              errmsg={errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="totalStudents"
              label="Total de estudiantes"
              variant="standard"
              control={control}
              rules={RULES.number}
              error={Boolean(errors.totalStudents)}
              errmsg={errors.totalStudents}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="count"
              label="Total de docentes"
              variant="standard"
              control={control}
              rules={RULES.required}
              error={Boolean(errors.count)}
              errmsg={errors.count}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="direction"
              label="Dirección"
              variant="standard"
              control={control}
              rules={RULES.required}
              error={Boolean(errors.direction)}
              errmsg={errors.direction}
              multiline={true}
            />
          </Grid>
        </Grid>
        <CustomButton
          label={isNewRecord ? 'Crear' : 'Actualizar'}
          typeVariant="outlined"
          typeAction="submit"
          disabled={loadingService}
        />
      </Stack>
    </form>
  );
};

export default FormSchools;
