import { useState, useEffect } from 'react';
import { CustomButton, TextField } from 'components/index';
import { RULES, localToken } from 'constants/index';
import styles from './Login.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormHook } from 'hooks/useFormHook';
import { Stack } from '@mui/material';
import { getLoginServices } from 'services/auth';
import { useAlert } from 'hooks/useAlert';
import { searchItemLocal, setItemLocal } from 'utils/helpers';
import NAVIGATION from 'routes/navigation';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


const DEFAULT_VALUES = {
  username: '',
  password: ''
};
const Login = () => {

  const [captcha, setCaptcha] = useState(null)
  const token = searchItemLocal(localToken);
  const navigate = useNavigate();
  const { openAlert } = useAlert();
  const { control, handleSubmit, errors, setError, clearErrors } =
    useFormHook(DEFAULT_VALUES);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {

    if (captcha !== null) {
      try {
        setIsLoading(true);
        const response = await getLoginServices({ data });
        setItemLocal(localToken, response?.data?.token.token);
        setItemLocal('dataUser', response?.data?.data[0]);
      } catch (error: any) {

        openAlert(error.response.data.message, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }

    } else {
      openAlert("complete el reCAPTCHA", { variant: 'error' });
    }
  };

  function onChange(value: any) {
    setCaptcha(value)
  }

  useEffect(
    () => {
      if (token) navigate(NAVIGATION.HOME);
    },
    [token, navigate])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Bienvenido</h2>
      <hr style={{ height: "2.5px" }} />
      <div className="options">
        <p className="unirse">Unirse</p>
      </div>
      <Stack spacing={3}>
        <div style={{ display: "flex" }}>
          <PersonOutlineOutlinedIcon style={{ marginTop: "auto", fontSize: "30px" }} />
          <TextField
            name='email'
            label='Correo'
            control={control}
            variant='standard'
            type='email'
            error={Boolean(errors.username)}
            errmsg={errors.username}
            rules={RULES.required}
          />
        </div>

        <div style={{ display: "flex" }}>
          <LockOutlinedIcon style={{ marginTop: "auto", fontSize: "30px" }} />
          <TextField
            name='password'
            label='Contraseña'
            control={control}
            type='password'
            variant='standard'
            error={Boolean(errors.password)}
            errmsg={errors.password}
            rules={RULES.password}
          />
        </div>

        <div className={styles.buttonWrapper}>
          <ReCAPTCHA
            sitekey='6LfRhFUlAAAAAEPQJw4IhtfZ8KyTjK2F_2Koc8tA'
            onChange={onChange}
          />
        </div>


        <button className={styles.loginButton} type="submit">
          {isLoading? 
          <div className={styles.loaderContainer}>
            <div className={styles.loader}></div>
          </div>: 
          "Iniciar Sesión"}
        </button>

        {/* <CustomButton
          label='Iniciar Sesion'
          typeVariant='outlined'
          typeAction='submit'
          disabled={isLoading}
        /> */}
      </Stack>

      <div className={styles.olvido}>
        <NavLink to='/recuperar' end>
          <span>
            Olvido su contraseña
          </span>
        </NavLink>
      </div>
      <div className={styles.olvido}>
        <NavLink to='/' end>
          <span>
            Ayuda
          </span>
        </NavLink>
      </div>
    </form>
  );
};

export default Login;
