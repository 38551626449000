import { request } from 'lib/request';

// get all Activities
export function getLoginServices({ data, authRequire }: any) {
  return request('/login', {
    method: 'post',
    authRequire,
    data: data
  });
}

//register
export function getRegisterServices({ data, authRequire }: any) {
  return request('/register', {
    method: 'post',
    authRequire,
    data: data
  });
}
