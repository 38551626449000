import React, { useState } from 'react';
import { TFormProps, TIconsList } from 'types/index';
import { useFormHook } from 'hooks/useFormHook';
import { CircularProgress, Stack } from '@mui/material';
import { TextField, CustomButton } from 'components/index';
import { RULES } from 'constants/index';
const FormFundacite = ({ onSubmit, loadingService, rowData }: TFormProps) => {
  const { data, isNewRecord } = rowData;

  const { control, errors, handleSubmit } = useFormHook(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <TextField
          name="name"
          label="Nombre de la actividad"
          control={control}
          variant="standard"
          rules={RULES.no_special}
          error={Boolean(errors.name)}
          errmsg={errors.name}
          autoFocus
        />

        <CustomButton
          label={isNewRecord ? 'Crear' : 'Actualizar'}
          typeVariant="outlined"
          typeAction="submit"
          disabled={loadingService}
        />
      </Stack>
    </form>
  );
};

export default FormFundacite;
