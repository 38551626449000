import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function Bars({data}:any) {


  return (
    <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={500}
          height={500}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" textAnchor= "end" interval={0} angle={-40} height={200}  />
          <YAxis />
          <Tooltip />
          <Bar  type="monotone" dataKey="total" stroke="#8884d8" fill="#ffc658" />

        </BarChart>
      </ResponsiveContainer>
  )
}
