'use client';
import React, { useMemo, useState, useEffect } from 'react';
import styles from 'styles/Layout.module.scss';
import { Typography } from '@mui/material';
import {
  ActionsButtonsTable,
  CustomButton,
  CardNormal,
  Table,
  CustomModal,
  FormFundacite,
  DialogConfirm
} from 'components/index';
import {
  GridCellParams,
  GridColDef,
  GridValidRowModel
} from '@mui/x-data-grid';
import {
  TGenericResponse,
  TGenericResponseError,
  TOpenModals,
  TPageState
} from 'types/index';
import { getAllFundacitesServices, createFundacitesServices, updateFundacitesServices, deleteFundacitesServices } from 'services/fundacites'
import { searchItemLocal } from 'utils/helpers';
import { useAlert } from 'hooks/useAlert';
import { differenceInCalendarYears, parse } from 'date-fns';


/* inicializar los datos para el formulario */


const Fundacites = () => {
  const { openAlert } = useAlert();
  const dataUser = searchItemLocal('dataUser')
  const initialDatarow = {
    id: '',
    firstName: '',
    lastName: '',
    identity: '',
    birthDate: '',
    gender: '',
    email: '',
    phone: '',
    professionId: '',
    responsabilityId: '',
    enteId: dataUser?.userRole?.enteId
  };
  const [openModal, setOpenModal] = useState<TOpenModals>({
    modalCreate: false,
    dialogDelete: false
  });

  const [loadingService, setLoadingService] = useState<boolean>(false);

  const [rowData, setRowData] = useState({
    data: initialDatarow,
    isNewRecord: true
  });

  const [pageState, setPageState] = React.useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 50
  });
  const getFundacites = async () => {
    try {
      setPageState((prev: TPageState) => ({ ...prev, isLoading: true }));
      const { data } = await getAllFundacitesServices({
        params: {
          skip: pageState?.page * pageState?.pageSize,
          take: pageState?.pageSize,
          id: dataUser.userRole.enteId,
          deleted: false
        },
        authRequire: true,
      });

      console.log(data)

      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data?.data,
        total: data?.total,
      }));
    } catch (err: any) {
      openAlert(err.response.data.message, { variant: 'error' })
    }
  };

  const handleCreate = () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: initialDatarow, isNewRecord: true });
  };
  const handleEditRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const handleDeleteRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogDelete: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const onSubmit = async (data: any) => {
    const payload = {
      enteId: dataUser.userRole.enteId,
      ...data
    };
    delete payload.profession;
    delete payload.responsible;
    delete payload.ente;
    try {
      setLoadingService(true);
      const id = payload.id;
      delete payload.id;
      if (rowData.isNewRecord) {
        await createFundacitesServices({ payload: payload, authRequire: true, });
        openAlert('Creacion realizada exitosamente')
      } else {
        await updateFundacitesServices({ payload: payload, params: id, authRequire: true, });
        openAlert('Modificacion realizada exitosamente')
      }
    } catch (err) {
      openAlert('error en en la operación', { variant: 'error' })
    } finally {
      setLoadingService(false);
      getFundacites();
      setOpenModal({ modalCreate: false });
    }
  };

  const onDeleteRow = async (id: unknown) => {
    try {
      setLoadingService(true);
      (await deleteFundacitesServices({
        params: id as number, authRequire: true
      })) as TGenericResponse;

    } catch (err) {
      openAlert('error en en la operación', { variant: 'error' })
    } finally {
      setLoadingService(false);
      getFundacites();
      setOpenModal({ dialogDelete: false });
    }
  };


  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 40 },
      { field: 'firstName', headerName: 'Nombre', width: 180 },
      { field: 'lastName', headerName: 'Apellido', width: 180 },
      { field: 'identity', headerName: 'Cedula', width: 180 },
      {
        field: 'birthDate', headerName: 'Edad', width: 50,
        renderCell: params => {

          return differenceInCalendarYears(new Date(), parse(params.value, "yyyy-dd-mm", new Date()))
        }
      },
      {
        field: 'gender',
        headerName: 'Genero',
        width: 80,
        renderCell: params => {
          return params.row.gender ? "Masculino" : "Femenino"
        }
      },
      { field: 'email', headerName: 'Correo', width: 180 },
      { field: 'phone', headerName: 'Telefono', width: 140 },
      {
        field: 'profession', headerName: 'Profesión', width: 140,
        renderCell: params => {
          return params.row.profession.name
        }
      },
      {
        field: 'responsible',
        headerName: 'Responsabilidad',
        width: 180,
        renderCell: params => {

          return params.row.responsible.name
        }
      },
      {
        field: 'actions',
        headerName: 'Acciones',
        renderCell: params =>
          <ActionsButtonsTable
            handleEditRow={handleEditRow(params)}
            handleDeleteRow={handleDeleteRow(params)}
          />,
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true
      }
    ],
    []
  );

  useEffect(() => {
    getFundacites();
  }, [pageState.pageSize, pageState.page]);

  return (
    <section>
      <div className={styles['modules__header']}>
        <Typography className={styles['page-title']} component="h1">
          {dataUser ? dataUser?.userRole?.ente?.name : 'Fundacite'}
        </Typography>
        {
          dataUser.userRole.roleId > 4 ?
            "" :
            <CustomButton
              typeVariant="contained"
              label="Agregar Nuevo"
              onClick={handleCreate}
              className={styles['button-header']}
            />

        }
      </div>
      <CardNormal>
        <Table
          pageState={pageState}
          setPageState={setPageState}
          columns={columns}
        />
      </CardNormal>

      <CustomModal
        modalTitle={
          rowData.isNewRecord ? 'Crear responsable' : 'Actualizar responsable'
        }
        openModal={openModal.modalCreate as boolean}
        setOpenModal={setOpenModal}>
        <FormFundacite
          onSubmit={onSubmit}
          rowData={rowData}
          loadingService={loadingService}
        />
      </CustomModal>

      <DialogConfirm
        onDelete={onDeleteRow}
        title="Eliminar Responsable"
        openModal={openModal.dialogDelete}
        setOpenModal={setOpenModal}
        idRow={rowData.data.id}>
        ¿Estas seguro de eliminar este Responsable?
      </DialogConfirm>
    </section>
  );
};

export default Fundacites;
