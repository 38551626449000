import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Stack, Drawer, List, Divider, IconButton, Hidden } from '@mui/material';
import ListMenu from './ListMenu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import LogoutIcon from '@mui/icons-material/Logout';
import NAVIGATION from 'routes/navigation';
import { useNavigate } from 'react-router-dom';
import { removeItemLocal, searchItemLocal } from 'utils/helpers';
import { localToken } from 'constants/index';

const MENU = [
  {
    name: 'fundacites',
    path: '/dashboard/fundacites'
  },
  {
    name: 'actividades ludicas',
    path: '/dashboard/actividades-ludicas'
  },
  {
    name: 'usuarios',
    path: '/dashboard/usuarios'
  },
  {
    name: 'instituciones',
    path: '/dashboard/instituciones'
  },
  {
    name: 'sitios',
    path: '/dashboard/sitios'
  },
  {
    name: 'rutas',
    path: '/dashboard/rutas'
  }
];

const drawerWidth = 240;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  /* ...theme.mixins.toolbar, */
  justifyContent: 'flex-end'
}));

const DrawerMenu: React.FC<DrawerMenuProps> = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const logOut = () => {
    removeItemLocal(localToken);
    navigate(NAVIGATION.LOGIN);
  };

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  function selectImage() {
    switch (getRandomInt(3)) {
      case 0:
        return <img style={{ opacity: '0.5' }} src="../copia-espacio-chicas-libros-abrazandose.png" alt="copia-espacio-chicas-libros-abrazandose" />
      case 1:
        return <img style={{ opacity: '0.5' }} src="../espacio-copia-amistad-escuela-ninas.png" alt="copia-espacio-chicas-libros-abrazandose" />
      case 2:
        return <img style={{ opacity: '0.5' }} src="../chicos-sonrientes-tiro-medio-al-aire-libre.png" alt="copia-espacio-chicas-libros-abrazandose" />
    }
  }

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          overflow: "hidden",
          height: '100vh',
        }
      }}
      variant="persistent"
      anchor="left"
      open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr'
            ? <ChevronLeftIcon />
            : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      {/* <Divider /> */}
      <List sx={{
        flexGrow: '1',
        background: "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(215,216,219,1) 20%, rgba(185,186,192,1) 22%)",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '0'
      }}>
        <ListMenu />
        <div>
          <img style={{ opacity: '1' }} src="../inagenSB_Semillero__02.png" alt="copia-espacio-chicas-libros-abrazandose" />
        </div>
      </List>
      {/* <Divider /> */}
      {/* <Stack direction={'row'} justifyContent="space-around">
        <IconButton
          onClick={() => logOut()}
          aria-label="cerrar sesion"
          color="primary">
          <LogoutIcon />
        </IconButton>
      </Stack> */}
    </Drawer>
  );
};

type DrawerMenuProps = {
  open: boolean;
  handleDrawerClose: React.MouseEventHandler<HTMLButtonElement>;
};

export default DrawerMenu;
