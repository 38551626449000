import { request } from 'lib/request';

// get all Activities
export function getAllResponsabilitiesServices({ params, authRequire }: any) {
  const { skip, take } = params as any;
  return request('/responsabilities', {
    method: 'get',
    authRequire,
    params: { skip, take, ...params }
  });
}
