import React, { useCallback, useState } from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";

/* const data = [
  { name: "Female", value: 400 },
  { name: "Male", value: 300 },
]; */



const renderColorfulLegendText = (value: any) => {
  return <span style={{ color: "black" }}>{value}</span>;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function PieChartc({ data }: any) {

  const COLORS = ["#FC6C85", "#1E90FF"];

  return (
    <PieChart width={500} height={500}>
      <Pie
        data={data}
        // cx={300}
        // cy={300}
        labelLine={false}
        label={renderCustomizedLabel}
        /* innerRadius={40}
        outerRadius={80} */
        fill="#8884d8"
        dataKey="value"
      >
        {/* {data.map((index: any) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))} */}

        <Cell fill={"#FC6C85"} />
        <Cell fill={"#1E90FF"} />
      </Pie>

      <Legend
        formatter={renderColorfulLegendText}
        layout="vertical"
        verticalAlign="middle"
        align="right"
        iconSize={40}
      />
      {data[0].name}
    </PieChart>
  );
}
