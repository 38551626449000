import { useState, useEffect } from 'react';

import {
  CustomButton,
  TextField,
  TextFieldAutoC,
  TextFieldSelect
} from 'components/index';
import { RULES, localToken } from 'constants/index';
import styles from './Register.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormHook } from 'hooks/useFormHook';
import { Stack } from '@mui/material';
import { getRegisterServices } from 'services/auth';
import { getAllEntitiesServices } from 'services/entities';
import { useAlert } from 'hooks/useAlert';
import { searchItemLocal, setItemLocal } from 'utils/helpers';
import NAVIGATION from 'routes/navigation';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

const DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  identity: '',
  email: '',
  password: '',
  password2: '',
  rol: '',
  entity: ''
};
const Register = () => {
  const [entesOptions, setEntesOptions] = useState([]);
  const [captcha, setCaptcha] = useState(null);
  const token = searchItemLocal(localToken);
  const navigate = useNavigate();
  const { openAlert } = useAlert();
  const {
    control,
    handleSubmit,
    handleBlurPassword,
    errors,
    setError,
    clearErrors
  } = useFormHook(DEFAULT_VALUES);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    handleBlurPassword()
    if (captcha !== null) {
      try {
        setIsLoading(true);
        const response = await getRegisterServices({ data });
        console.log(response.data);
      } catch (error) {
        const err = error as any;
        openAlert(err.response.data.errors[0].message, {
          variant: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  function onChange(value: any) {
    setCaptcha(value);
  }

  async function getEntities() {
    try {
      const { data } = await getAllEntitiesServices({ authRequire: true });
      setEntesOptions(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getEntities();
  }, []);

  useEffect(
    () => {
      if (token) navigate(NAVIGATION.HOME);
    },
    [token, navigate]
  );

  const options = [
    {
      id: '3',
      name: 'Presidente de Ente'
    },
    {
      id: '4',
      name: 'Delegado'
    }
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>¡Registrate!</h1>
      <div>
        <div className={styles.group}>
          <TextField
            name="firstName"
            label="Nombre"
            control={control}
            variant="standard"
            error={Boolean(errors.firstName)}
            errmsg={errors.firstName}
            rules={RULES.required}
          />

          <TextField
            name="lastName"
            label="Apellido"
            control={control}
            variant="standard"
            error={Boolean(errors.lastName)}
            errmsg={errors.lastName}
            rules={RULES.required}
          />
        </div>
        <div className={styles.group}>
          <TextField
            name="email"
            label="Email"
            control={control}
            variant="standard"
            type="email"
            error={Boolean(errors.email)}
            errmsg={errors.email}
            rules={RULES.required}
          />

          <TextField
            name="identity"
            label="Cédula"
            control={control}
            variant="standard"
            error={Boolean(errors.identity)}
            errmsg={errors.identity}
            rules={RULES.cedula}
          />
        </div>

        <div className={styles.group}>
          <TextField
            name="password"
            label="Contraseña"
            control={control}
            type="password"
            variant="standard"
            error={Boolean(errors.password)}
            errmsg={errors.password}
            rules={RULES.password}
          />

          <TextField
            name="password2"
            label="Repetir Contraseña"
            control={control}
            handleBlur={handleBlurPassword}
            type="password"
            variant="standard"
            error={Boolean(errors.password2)}
            errmsg={errors.password2}
            rules={RULES.password}
          />
        </div>

        <div className={styles.group}>
          <TextFieldSelect
            name="ente"
            label="Ente"
            control={control}
            variant="standard"
            error={Boolean(errors.ente)}
            options={entesOptions}
            rules={RULES.required}
          />

          <TextFieldSelect
            name="role"
            label="Rol"
            control={control}
            variant="standard"
            error={Boolean(errors.role)}
            options={options}
            rules={RULES.required}
          />
        </div>

        <div className={styles.captchaWrapper}>
          <ReCAPTCHA
            sitekey="6LfRhFUlAAAAAEPQJw4IhtfZ8KyTjK2F_2Koc8tA"
            onChange={onChange}
          />
        </div>
      </div>

      <div className={styles.buttonWrapper}>
        <CustomButton
          label="Registrarse"
          typeVariant="outlined"
          typeAction="submit"
          disabled={isLoading}
        />
      </div>

      <div className={styles.olvido}>
        <NavLink to="/iniciar-sesion" end>
          Iniciar Sesion
        </NavLink>
      </div>
    </form>
  );
};

export default Register;
