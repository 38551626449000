import React, { useState, useEffect } from 'react';
import { TFormProps, TIconsList } from 'types/index';
import { useFormHook } from 'hooks/useFormHook';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { TextField, CustomButton, TextFieldSelect } from 'components/index';
import { RULES } from 'constants/index';
//import DateField from '../Fields/DateField';

import { getAllResponsabilitiesServices } from 'services/responsabilities';
import { getAllProfessionsServices } from 'services/professions';
import { saimeService } from 'services/saime';
const genderOptions = [
  {
    id: 0,
    name: 'Femenino',
    value: 'F'
  },
  {
    id: 1,
    name: 'Masculino',
    value: 'M'
  }
];
const FormFundacite = ({ onSubmit, loadingService, rowData }: TFormProps) => {
  const { data, isNewRecord } = rowData;

  const { control, errors, handleSubmit } = useFormHook(data);
  const [responsabilities, setResponsabilities] = useState([]);
  const [professions, setProfessions] = useState([]);

  const getSaime = async () => {
    try {
      const response = await saimeService(
        { origen: 'V', cedula: '24214220' } as any
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getResponsabilities = async () => {
    try {
      const response = (await getAllResponsabilitiesServices({
        params: {},
        authRequire: true
      })) as any;
      setResponsabilities(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfessions = async () => {
    try {
      const response = await getAllProfessionsServices({
        params: {},
        authRequire: true
      });
      setProfessions(response.data.data);
    } catch (error) {
      console.log('profe', error);
    }
  };

  useEffect(() => {
    getResponsabilities();
    getProfessions();
    //getSaime();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        marginTop={10}
        direction="column"
        spacing={3}
        justifyContent="center"
        maxWidth={550}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextFieldSelect
              name="responsabilityId"
              label="Responsabilidad"
              control={control}
              variant="standard"
              options={responsabilities}
              rules={RULES.required}
              error={Boolean(errors.responsabilityId)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldSelect
              name="professionId"
              label="Profesión"
              control={control}
              variant="standard"
              options={professions}
              rules={RULES.required}
              error={Boolean(errors.professionId)}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="identity"
              label="Cedula"
              control={control}
              variant="standard"
              rules={RULES.cedula}
              error={Boolean(errors.identity)}
              errmsg={errors.identity}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="birthDate"
              label="Fecha de nacimiento"
              control={control}
              variant="standard"
              type="date"
              labelProps={{ shrink: true }}
              rules={RULES.required}
              error={Boolean(errors.age)}
              errmsg={errors.age}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="firstName"
              label="Nombre"
              control={control}
              variant="standard"
              rules={RULES.names}
              error={Boolean(errors.firstName)}
              errmsg={errors.firstName}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="lastName"
              label="Apellido"
              control={control}
              variant="standard"
              rules={RULES.names}
              error={Boolean(errors.lastName)}
              errmsg={errors.lastName}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldSelect
              name="gender"
              label="Genero"
              control={control}
              variant="standard"
              rules={RULES.required}
              options={genderOptions}
              error={Boolean(errors.gender)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="phone"
              label="Telefono"
              control={control}
              variant="standard"
              rules={RULES.number}
              error={Boolean(errors.phone)}
              errmsg={errors.phone}
              autoFocus
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="email"
              label="Correo"
              control={control}
              variant="standard"
              rules={RULES.required}
              error={Boolean(errors.email)}
              errmsg={errors.email}
              autoFocus
              type="email"
            />
          </Grid>
        </Grid>
        <CustomButton
          label={isNewRecord ? 'Crear' : 'Actualizar'}
          typeVariant="outlined"
          typeAction="submit"
          disabled={loadingService}
        />
      </Stack>
    </form>
  );
};

export default FormFundacite;
