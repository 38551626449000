
import React, { useMemo, useState, useEffect } from 'react';
import styles from 'styles/Layout.module.scss';
import { Typography } from '@mui/material';
import {
  ActionsButtonsTable,
  CustomButton,
  CardNormal,
  Table,
  CustomModal,
  FormSchools,
  DialogConfirm
} from 'components/index';
import {
  GridCellParams,
  GridColDef,
  GridValidRowModel
} from '@mui/x-data-grid';
import {
  TGenericResponse,
  TGenericResponseError,
  TOpenModals,
  TPageState
} from 'types/index';
import { getAllInstitutions,createInstitutions,updateInstitutions,deleteInstitutions } from 'services/institutions';
import { useAlert } from 'hooks/useAlert';
import { searchItemLocal } from 'utils/helpers';



/* inicializar los datos para el formulario */
const initialDatarow = {
  id: '',
  name: '',
  codPlantel: '',
  estadoId: '',
  municipioId: '',
  parroquiaId: '',
  direction: '',
  totalStudents:'',
  count:'',
  phone: '',
  type:''
};




const Instituciones = () => {
  const { openAlert } = useAlert();
  const dataUser = searchItemLocal('dataUser')
  
  const [openModal, setOpenModal] = useState<TOpenModals>({
    modalCreate: false,
    dialogDelete: false
  });

  const [loadingService, setLoadingService] = useState<boolean>(false);

  const [rowData, setRowData] = useState({
    data: initialDatarow,
    isNewRecord: true
  });

  const [pageState, setPageState] = React.useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 50
  });
  
  const getInstitutions = async () => {
    try {
      setPageState((prev: TPageState) => ({ ...prev, isLoading: true }));
      const { data } = await getAllInstitutions({
        params: {
          skip: pageState?.page * pageState?.pageSize,
          take: pageState?.pageSize,
          estadoId:dataUser.userRole.roleId === 1 ? null: dataUser.userRole.ente.estadoId
        },
        authRequire: true,
      });
  
      console.log(dataUser.userRole[0])

      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data.data,
        total: data?.total,
      }));
    } catch (err: any) {
      openAlert('error en en la operación', {variant:'error'})
    }
  };

  const handleCreate = () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: initialDatarow, isNewRecord: true });
  };
  const handleEditRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const handleDeleteRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogDelete: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const onSubmit = async (data: unknown) => {
    const payload = data as any;
    try {
      setLoadingService(true);
      const id = payload.id;
      delete payload.id;
      if (rowData.isNewRecord) {
        await createInstitutions({ payload: payload,   authRequire: true, });
       
      } else {
        await updateInstitutions({ payload: payload, params: id,   authRequire: true, });

      }
    } catch (err) {
      openAlert('error en en la operación', {variant:'error'})
    } finally {
      setLoadingService(false);
      getInstitutions();
      setOpenModal({ modalCreate: false });
    }
  };

  const onDeleteRow = async (id: unknown) => {
    try {
      setLoadingService(true);
      (await deleteInstitutions({
        params: id as number
      })) as TGenericResponse;
   
    } catch (err) {
      openAlert('error en en la operación', {variant:'error'})
    } finally {
      setLoadingService(false);
      getInstitutions();
      setOpenModal({ dialogDelete: false });
    }
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 40 },
      { field: 'name', headerName: 'Nombre', width: 400 },
      { field: 'codPlantel', headerName: 'Codigo Plantel', width: 150 },
   
      { field: 'estadoId', headerName: 'Estado', width: 180 },
      { field: 'direction', headerName: 'Dirección', width: 400 },

      { field: 'totalStudents', headerName: 'Total de estudiantes', width: 140 },
      { field: 'count', headerName: 'Total de docentes', width: 140 },
      { 
        field: 'actions',
        headerName: 'Acciones',
        renderCell: params =>
          <ActionsButtonsTable
            handleEditRow={handleEditRow(params)}
            handleDeleteRow={null}
          />,
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true
      },
    ],
    []
  );
  useEffect(() => {
    getInstitutions();
  }, [pageState.pageSize, pageState.page]);

  return (
    <section>
      <div className={styles['modules__header']}>
        <Typography className={styles['page-title']} component="h1">
          Instituciones
        </Typography>
        <CustomButton
          typeVariant="contained"
          label="Agregar Nuevo"
          onClick={handleCreate}
          className={styles['button-header']}
        />
      </div>
      <CardNormal>
        <Table
          pageState={pageState}
          setPageState={setPageState}
          columns={columns}
        />
      </CardNormal>

      <CustomModal
        modalTitle={
          rowData.isNewRecord ? 'Crear institucion' : 'Actualizar institucion'
        }
        openModal={openModal.modalCreate as boolean}
        setOpenModal={setOpenModal}>
        <FormSchools
          onSubmit={onSubmit}
          rowData={rowData}
          loadingService={loadingService}
        />
      </CustomModal>

      <DialogConfirm
        onDelete={onDeleteRow}
        title="Eliminar Institucion"
        openModal={openModal.dialogDelete}
        setOpenModal={setOpenModal}
        idRow={rowData.data.id}>
        ¿Estas seguro de eliminar esta institucion?
      </DialogConfirm>
    </section>
  );
};

export default Instituciones;
