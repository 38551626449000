import React, { useMemo, useState, useEffect } from 'react';
import styles from 'styles/Layout.module.scss';
import { Stack, Typography } from '@mui/material';
import axios from 'axios'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getAllEntitiesServices } from 'services/entities';
import {
  ActionsButtonsTable,
  CustomButton,
  CardNormal,
  TableE,
  CustomModal,
  FormRutas,
  DialogConfirm,
  FormImportExcel
} from 'components/index';
import {
  GridCellParams,
  GridColDef,
  GridValidRowModel
} from '@mui/x-data-grid';
import {
  TGenericResponse,

  TOpenModals,
  TPageState
} from 'types/index';
import {
  getAllAcademicRoutesServices,
  createAcademicRoutesServices,
  updateAcademicRoutesServices,
  deleteAcademicRoutesServices,
} from 'services/academicRoutes'
import { getUserServices } from 'services/users'
import { searchItemLocal } from 'utils/helpers';
import { generateReportServices } from 'services/report';
import { useAlert } from 'hooks/useAlert';



const AcademicRoutes = () => {

  const { openAlert } = useAlert();
  const dataUser = searchItemLocal('dataUser')
  const [openModal, setOpenModal] = useState<TOpenModals>({
    modalCreate: false,
    modalImport: false,
    dialogDelete: false,
    modalDetails: false
  });
  /* inicializar los datos para el formulario */
  const initialDatarow = {
    id: '',
    name: '',
    siteId: undefined,
    enteId: dataUser.userRole.enteId,
    estadoId: dataUser.userRole.ente.estado.id,
    activityId: undefined,
    datePlanned: undefined,
    responsibleFirstName: '',
    responsibleLastName: '',
    responsibleIdentity: '',
    responsiblePhone: '',
    responsibleCargo: undefined,
    estudiantes: undefined,
    observations: '.',
    createdAt: undefined,
    createBy: undefined,
    fileExtension: undefined
  };


  const [loadingService, setLoadingService] = useState<boolean>(false);

  const [rowData, setRowData] = useState({
    data: initialDatarow,
    isNewRecord: true
  });

  const formDataImport: { startDate: any, endDate: any, enteId: string } = {
    startDate: '',
    endDate: '',
    enteId: dataUser.userRole.enteId,
  }



  const [pageState, setPageState] = React.useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 50
  });




  const downloadAcademicRoute = async (name: any, fileExtension: any) => {

    console.log(fileExtension)
    try {



      /* .then((dat) => {
        console.log(dat)

        var binaryData = [];
        binaryData.push(dat.data);
        const url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/xlsx"}))


        const url = URL.createObjectURL(dat.data) 
        console.log(url)
        const a = document.createElement('a')
        a.href = url
        a.download = 'test.xlsx'
        document.body.appendChild(a)
        a.click();
        a.remove()
        URL.revokeObjectURL(url)
      }) */
      /* var binaryData = [];
      binaryData.push(data.data)
      console.log(new Blob(binaryData, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}))

      const url = URL.createObjectURL(new Blob(binaryData, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}))

      console.log(url)

      const a = document.createElement('a')
      a.href = url
      a.download = 'test.xlsx'
      document.body.appendChild(a)
      a.click();
      a.remove()
      URL.revokeObjectURL(url) */



      const lmao = axios.create({
        baseURL: 'https://pnsc.mincyt.gob.ve',
      });

      lmao.interceptors.request.use(config => {
        config.responseType = 'blob'
        config.params = {
          // add your default ones
          timestamp: new Date().getTime(),
          name: name,
          fileExtension: fileExtension,
          // spread the request's params
          ...config.params,
        };
        return config;
      });

      lmao.get('/api/download').then((dat) => {
        const url = URL.createObjectURL(dat.data)

        const a = document.createElement('a')
        a.href = url
        a.download = `${name}${fileExtension}`
        /* a.download = `solo.xls` */
        document.body.appendChild(a)
        a.click()
        a.remove()
        URL.revokeObjectURL(url)
        console.log(url)
        console.log(a)
        localStorage.removeItem('download')
      })


      //?name=${name}&&timestamp=${new Date().getTime()}
      /* console.log("peticion de nuevo?")
      await axios.get(`http://localhost:3333/api/download?name=${name}&&timestamp=${new Date().getTime()}`,{
        responseType: 'blob'
      }).then((dat) => {
        const url = URL.createObjectURL(dat.data)
        
        const a = document.createElement('a')
        a.href = url
        a.download = `${name}.xlsx`
        document.body.appendChild(a)
        a.click()
        a.remove()
        URL.revokeObjectURL(url)
        console.log(url)
        console.log(a)
        localStorage.removeItem('download')
      }) */

      /* console.log(data1)


      const url = URL.createObjectURL(data1.data)

      const a = document.createElement('a')
      a.href = url
      a.download = 'test.xlsx'
      document.body.appendChild(a)
      a.click()
      a.remove()
      URL.revokeObjectURL(url)
      console.log(url)
      console.log(a) */

    } catch (err: any) {
      console.log(err)
    }
  };


  const getAcademicRoutes = async (aja?: string) => {
    try {
      setPageState((prev: TPageState) => ({ ...prev, isLoading: true }));
      const { data } = await getAllAcademicRoutesServices({
        params: {
          skip: pageState?.page * pageState?.pageSize,
          take: pageState?.pageSize,
          filters: aja ? aja : filterOption ? filterOption : dataUser.filters ? dataUser.filters : "",
          deleted: false,
          enteId: dataUser.userRole.roleId === 1 ? null : dataUser.userRole.enteId,
        },
        authRequire: true,
      });


      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data?.data ? data?.data : [],
        total: data?.total,
      }));
    } catch (err: any) {
      console.log(err)
    }
  };

  const handleCreate = () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: initialDatarow, isNewRecord: true });
  };

  const handleCreateImport = () => {
    setOpenModal({ modalImport: true });
    ;
  };
  const handleEditRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: dataRow.row, isNewRecord: false });

  };

  const handleDeleteRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogDelete: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };


  const handleViewRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ modalDetails: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const handleRowClick = (dataRow: GridCellParams) => () => {
    console.log(dataRow.row)
    console.log("lmao")
  };

  const onSubmitImport = async (data: unknown) => {

    try {
      setLoadingService(true);
      const res = await generateReportServices({
        params: data as any,
        authRequire: true,
      });
      openAlert(res.data.message)

      console.log(res)
    } catch (error) {
      openAlert('error a la hora de generar el reporte', { variant: 'error' })
      console.log(error)
    } finally {
      setLoadingService(false);
      setOpenModal({ modalImport: false });
    }

  }

  const checking = async (dataRow: GridCellParams) => {
    const payload = dataRow.row as any;
    try {
      setLoadingService(true);
      const id = payload.id;
      delete payload.id;
      /* delete payload.activity
      delete payload.ente */
      payload.check = !payload.check
      await updateAcademicRoutesServices({
        payload: payload, params: id,
        authRequire: true,
      });

    } catch (err) {

      console.log(err)
    } finally {
      setLoadingService(false);
      getAcademicRoutes();
      setOpenModal({ modalCreate: false });
    }
  };

  const onSubmit = async (data: unknown) => {
    const payload = data as any;
    try {
      setLoadingService(true);
      const id = payload.id;
      delete payload.id;
      if (rowData.isNewRecord) {
        await createAcademicRoutesServices({
          payload: payload,
          authRequire: true,
        });

        openAlert('ruta creada exitosamente')

      } else {
        await updateAcademicRoutesServices({
          payload: payload, params: id,
          authRequire: true,
        });
        openAlert('ruta modificada exitosamente')
      }


    } catch (err) {

      console.log(err)
      openAlert("error al crear la ruta", { variant: 'error' })
    } finally {
      setLoadingService(false);
      getAcademicRoutes();
      setOpenModal({ modalCreate: false });
    }
  };

  const onDeleteRow = async (id: unknown) => {
    try {
      setLoadingService(true);
      (await deleteAcademicRoutesServices({
        params: id as number, authRequire: true,
      })) as TGenericResponse;

    } catch (err) {
      console.log(err)
    } finally {
      setLoadingService(false);
      getAcademicRoutes();
      setOpenModal({ dialogDelete: false });
    }
  };



  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 20 },
      {
        field: 'ente', headerName: 'Ente', width: 350, renderCell: params => {
          return <p>{params.value.name}</p>

        }
      },
      { field: 'name', headerName: 'Nombre de Ruta', width: 300 },
      { field: 'datePlanned', headerName: 'Fecha', width: 100 },
      /* { field: 'site', headerName: 'Sitios', width: 180, renderCell:params =>{ return params.value.name

      } }, */
      {
        field: 'activity', headerName: 'Tipo de Actividad', width: 180, renderCell: params => {
          return params.value.name

        }
      },
      { field: 'createdAt', headerName: 'Fecha de creación', width: 140 },
      { field: 'createBy', headerName: 'creado por', width: 200 },
      /* { field: 'plantel', headerName: 'plantel', width: 300,renderCell:params =>{ return params.value.name

      } }, */
      /* { field: 'responsibleFirstName', headerName: 'Nombre de Resposable', width: 180 },
      { field: 'responsibleLastName', headerName: 'Apellido de Resposable', width: 180 },
      { field: 'responsibleIdentity', headerName: 'Cèdula de Resposable', width: 180 },
      { field: 'responsiblePhone', headerName: 'Teléfono de Responsable', width: 180 },
      { field: 'responsibleCargo', headerName: 'Cargo de Responsable', width: 180 }, */
      {
        field: 'actions',
        headerName: 'Acciones',
        renderCell: params =>
          <>
            {
              dataUser.userRole.roleId <= 2 ?
                <input checked={params.row.check} type="checkbox" onClick={() => checking(params)} /> :
                null
            }
            {
              dataUser.userRole.roleId > 4 ?
                <ActionsButtonsTable
                  /* handleEditRow={handleEditRow(params)} */
                  /* handleDeleteRow={handleDeleteRow(params)} */
                  handleShowDetails={handleViewRow(params)}
                />

                :
                <ActionsButtonsTable
                  /* handleEditRow={handleEditRow(params)} */
                  handleDeleteRow={handleDeleteRow(params)}
                  handleShowDetails={handleViewRow(params)}
                />
            }
          </>,
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true
      }
    ],
    []
  );

  useEffect(() => {
    getAcademicRoutes();
  }, [pageState.pageSize, pageState.page]);


  async function getEntities() {
    try {
      const { data } = await getAllEntitiesServices({ authRequire: true });

      if (dataUser.userRole.roleId === 1) {
        setFilterOptions(data.data)
      }


      if (dataUser.filters) {
        let entitiesInCharge = dataUser.filters.split(",").map(Number)
        let aux: any = []

        data.data.forEach((element: any) => {
          if (entitiesInCharge.includes(element.id)) {
            aux.push({ id: element.id, acronim: element.acronim })
          }
        });

        setFilterOptions(aux)
      }



    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getEntities();
  }, []);

  const [filterOptions, setFilterOptions] = useState([])
  const [filterOption, setFilterOption] = useState('');

  const handleChange = async (event: SelectChangeEvent) => {
    setFilterOption(event.target.value as string);
    getAcademicRoutes(event.target.value as string);
  };
  const updateValue = async (event: any) => {

    getAcademicRoutes();
  };

  return (
    <section>
      <div className={styles['modules__header']}>
        <Typography className={styles['page-title']} component="h1">
          Rutas
        </Typography>
        {
          dataUser.userRole.roleId > 4 ?
            "" :
            <CustomButton
              typeVariant="contained"
              label="Agregar Nuevo"
              onClick={handleCreate}
              className={styles['button-header']}
            />
        }
      </div>
      <CardNormal>
        <Stack justifyContent={'flex-end'} gap={4}>

          {
            dataUser.userRole.roleId == 5 || dataUser.userRole.roleId < 2 ?
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Ente</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterOption}
                  label="Ente"
                  onChange={handleChange}
                >

                  {filterOptions.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id} >{item.acronim}</MenuItem>
                    )

                  })}

                </Select>
              </FormControl>
              : ""
          }


          <TableE
            pageState={pageState}
            setPageState={setPageState}
            columns={columns}
            handleRowClick={(params) => {
              setOpenModal({ details: true });
              setRowData({ data: params.row, isNewRecord: false });

            }}
          />

          <Stack direction={'row-reverse'}>

            <CustomButton
              typeVariant="contained"
              onClick={handleCreateImport}
              label="Exportar a excel"
              className={styles['button-header']}
            />

            {/* <div style={{ flex: '1', paddingRight: '5px' }}>
              Consolidado:  <br />
              -Candidad total de jovenes atendidos: 1200   -Cantidad femenino: 500👧  -Cantidad Masculino: 700👦 <br />
              -Preescolar:128 -1ro:239 -2do:230 -3ro:235 -4to:234 -5to:232 -6to:230 -7mo:230 -8vo:230 -9no:434 -4C.div:433 -5C.div:320 <br />
              -Edad promedio: 12
            </div> */}
          </Stack>
        </Stack>


      </CardNormal>

      <CustomModal
        modalTitle={
          rowData.isNewRecord ? 'Crear ruta' : 'Actualizar ruta'
        }
        openModal={openModal.modalCreate as boolean}
        setOpenModal={setOpenModal}>
        <FormRutas
          onSubmit={onSubmit}
          rowData={rowData}
          loadingService={loadingService}
        />
      </CustomModal>

      <CustomModal
        modalTitle='Exportar a Excel'
        openModal={openModal.modalImport as boolean}
        setOpenModal={setOpenModal}>
        <FormImportExcel
          onSubmit={onSubmitImport}
          rowData={formDataImport}
          loadingService={loadingService}
        />
      </CustomModal>

      <CustomModal
        modalTitle='Detalles'
        openModal={openModal.modalDetails as boolean}
        setOpenModal={setOpenModal}>
        <div>
          <h3>{rowData.data.name}</h3>
          fecha: {rowData.data.datePlanned} <br />
          fecha de creación: {rowData.data.createdAt} <br />
          Creado por: {rowData.data.createBy} <br />
          <button onClick={() => downloadAcademicRoute(rowData.data.name, rowData.data.fileExtension)}>
            Descargar Excel
          </button>
        </div>
      </CustomModal>

      <DialogConfirm
        onDelete={onDeleteRow}

        title="Eliminar Ruta"
        openModal={openModal.dialogDelete}
        setOpenModal={setOpenModal}
        idRow={rowData.data.id}>
        ¿Estas seguro de eliminar esta Ruta?
      </DialogConfirm>


    </section>
  );
};

export default AcademicRoutes;
