import { CircularProgress, Stack } from '@mui/material';
import {
  CustomButton,
  TextField,
  TextFieldAutoC,
  TextFieldSelect
} from 'components/index';
import { RULES } from 'constants/index';
import { useFormHook } from 'hooks/useFormHook';
import { useEffect, useState } from 'react';
import { getAllActivitiesServices } from 'services/activities';
import {
  getEstadosServices,
  getMunicipioServices,
  getParroquiaServices
} from 'services/estados';
import { getAllInstitutions } from 'services/institutions';
import { getAllSitesServices } from 'services/sites';
import { TFormProps } from 'types/index';
import { searchItemLocal } from 'utils/helpers';
import { getAllEntitiesServices } from 'services/entities';

const FormRutas = ({ onSubmit, loadingService, rowData }: TFormProps) => {
  const { data, isNewRecord } = rowData;
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  const dataUser = searchItemLocal('dataUser');
  const [entesOption, setEntesOptions] = useState([]);
  const { control, errors, handleSubmit, clearErrors } = useFormHook(data);
  const [idEMP, setIdEMP] = useState<any>({
    estadoId: '',
    municipioId: '',
    parroquiaId: ''
  });
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [parroquias, setParroquias] = useState([]);
  const [sitios, setSitios] = useState([]);
  const [school, setSchool] = useState<Array<{ codPlantel?: string }>>([]);
  const [value, setValue] = useState<string | undefined>(school.length ? school[0]?.codPlantel : '');
  const [inputValue, setInputValue] = useState('');
  const [file, setFile] = useState<any>([]);



  async function getActivities() {
    try {
      const { data } = await getAllActivitiesServices({
        params: {
          skip: 0,
          take: 100,
          deleted: false
        },
        authRequire: true
      });
      setActivitiesOptions(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  const handleFile = async (e: any) => {
    if (e.target.files) {
      clearErrors('estudiantes')
      setFile(e.target.files)
    }
  }



  const getEstados = async () => {
    try {
      const res = await getEstadosServices({ params: {}, authRequire: true });
      setEstados(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeEstado = (e: any) => {
    console.log(e)
    const estadoId = e.target.value;
    if (estadoId) {
      setIdEMP({ ...idEMP, estadoId });
    }
  };

  const getMunicipio = async (estadoId: number) => {
    const res = await getMunicipioServices({
      params: { estadoId },
      authRequire: true
    });
    setMunicipios(res.data.data);
  };

  const handleChangeParroquia = (e: any) => {
    const parroquiaId = e.target.value;
    if (parroquiaId) {
      setIdEMP((prevState: any) => ({ ...prevState, parroquiaId }));
    }
  };

  const handleChangeMunicipio = (e: any) => {
    const municipioId = e.target.value;
    if (municipioId) {
      setIdEMP((prevState: any) => ({ ...prevState, municipioId }));
    }
  };

  const getParroquia = async (municipioId: any) => {
    try {
      //console.count(municipioId);
      const res = await getParroquiaServices({
        params: { municipioId },
        authRequire: true
      });

      setParroquias(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSchool = async (parroquiaId: any) => {
    try {
      const res = await getAllInstitutions({
        params: { parroquiaId, take: 999 },
        authRequire: true
      });

      setSchool(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSites = async (estadoId: any) => {
    try {
      const res = await getAllSitesServices({
        params: { estadoId, take: 999 },
        authRequire: true
      });

      setSitios(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function getEntities() {
    try {
      const { data } = await getAllEntitiesServices({ authRequire: true });
      setEntesOptions(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  const preSubmit = async (payload: any) => {
    const codeSchool = inputValue.split(' ')[0]


    console.log(payload)
    const newPayload = {
      /* codPlantel: codeSchool, */
      ...payload,
    }


    const formData = new FormData()

    formData.append('file', file[0])
    formData.append('name', newPayload.name)
    formData.append('activityId', newPayload.activityId)
    formData.append('datePlanned', newPayload.datePlanned)
    formData.append('enteId', newPayload.enteId)
    formData.append('observations', newPayload.observations)
    formData.append('fileExtension', '.')
    formData.append('createBy', dataUser.id)
    formData.append('estadoId', newPayload.estadoId)
    formData.append('municipioId', newPayload.municipioId)
    formData.append('parroquiaId', newPayload.parroquiaId)
    //formData.append('codPlantel', newPayload.codPlantel)
    //formData.append('siteId', newPayload.siteId)
    //formData.append('responsibleFirstName', newPayload.responsibleFirstName)
    //formData.append('responsibleLastName', newPayload.responsibleLastName)
    //formData.append('responsibleIdentity', newPayload.responsibleIdentity)
    //formData.append('responsiblePhone', newPayload.responsiblePhone)
    //formData.append('responsibleCargo', newPayload.responsibleCargo) 

    onSubmit(formData)
  }



  useEffect(() => {
    setIdEMP({ ...idEMP, estadoId: data.estadoId });
  }, [data]);

  useEffect(() => {
    getEntities();
  }, []);


  useEffect(
    () => {
      if (idEMP.estadoId) {
        getMunicipio(idEMP.estadoId);
      }
    },
    [idEMP.estadoId]
  );

  useEffect(() => {
    getActivities();
  }, []);
  useEffect(
    () => {
      if (idEMP.estadoId) {
        getSites(idEMP.estadoId);
      }
    },
    [idEMP.estadoId]
  );

  useEffect(
    () => {
      if (idEMP.parroquiaId) {
        getSchool(idEMP.parroquiaId);
      }

    },
    [idEMP.parroquiaId]
  );

  useEffect(() => {
    if (data.codPlantel) {
      setValue(data.codPlantel)
    }
  }, [data])

  useEffect(
    () => {
      if (idEMP.municipioId) {
        getParroquia(idEMP.municipioId);
      }
    },
    [idEMP.municipioId]
  );

  useEffect(() => {
    getEstados();
  }, []);

  const cargos = [
    {
      id: 'director',
      name: 'Director'
    },
    {
      id: 'docente',
      name: 'Docente'
    }
  ];

  /*
    name 
    siteId
    codPlantel
    activityId
    datePlanned
    responsible nombre
    responsible apellido
    responsible telefono 
    reponsible cargo - director / profesor
  */

  return (
    <Stack>
      <form onSubmit={handleSubmit(preSubmit)} encType='multipart/form-data'>
        <Stack
          direction="column"
          spacing={3}
          maxWidth={550}>
          <TextField
            name="name"
            label="Nombre de la Ruta"
            control={control}
            variant="standard"
            rules={RULES.required}
            error={Boolean(errors.name)}
            errmsg={errors.name}
            autoFocus
          />


          <TextFieldSelect
            name="enteId"
            label="Ente"
            control={control}
            variant="standard"
            error={Boolean(errors.username)}
            options={entesOption}
            disabled={dataUser.userRole.role.id === 1 ? false : true}
          />

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextFieldSelect
              name="estadoId"
              variant="standard"
              control={control}
              options={estados}
              label="Estados"
              rules={RULES.required}
              error={Boolean(errors.estadoId)}
              itemLabel="nombre"
              handleChange={handleChangeEstado}
              /* disabled={dataUser.userRole.role.id === 1 ? false : true} */
            />

            <TextFieldSelect
              name="municipioId"
              variant="standard"
              control={control}
              options={municipios}
              label="Municipio"
              rules={RULES.required}
              error={Boolean(errors.municipioId)}
              itemLabel="nombre"
              handleChange={handleChangeMunicipio}
            />
          </div>


          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextFieldSelect
              name="parroquiaId"
              variant="standard"
              control={control}
              options={parroquias}
              label="Parroquia"
              rules={RULES.required}
              error={Boolean(errors.parroquiaId)}
              itemLabel="nombre"
              handleChange={handleChangeParroquia}
            />
            <TextFieldSelect
              name="activityId"
              variant="standard"
              control={control}
              options={activitiesOptions}
              label="Tipo de Actividad"
              rules={RULES.required}
              error={Boolean(errors.activityId)}
            />
          </div>

          {/* <div style={{ display: 'flex', width: '100%', gap: '20px' }}>


            <TextFieldSelect
              name="siteId"
              variant="standard"
              control={control}
              options={sitios}
              label="Sitios"
              rules={RULES.required}
              error={Boolean(errors.siteId)}
              itemLabel="name"
            />
          </div>
          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>

            <TextFieldAutoC
              label="escuelas"
              variant="standard"
              options={school}
              value={value}

              inputValue={inputValue}
              onChange={(_: any, newValue: any) =>
                setValue(newValue ? newValue : '')}
              onInputChange={(_: any, newInputValue: any) =>
                setInputValue(newInputValue)}
              rules={RULES.required}
              error={Boolean(errors.escuelas)}
              errmsg={errors.escuelas}
            />
          </div> */}

          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>


            <TextField
              name="datePlanned"
              label="Fecha Planeada"
              control={control}
              variant="standard"
              type="date"
              labelProps={{ shrink: true }}
              rules={RULES.required}
              error={Boolean(errors.datePlanned)}
              errmsg={errors.datePlanned}
            />
            {/* <TextFieldSelect
              name="responsibleCargo"
              variant="standard"
              control={control}
              options={cargos}
              label="Cargo del Responsable"
              rules={RULES.required}
              error={Boolean(errors.responsibleCargo)}
            /> */}
          </div>


          {/* <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextField
              name="responsibleFirstName"
              label="Nombre del Responsable"
              control={control}
              variant="standard"
              rules={RULES.no_special}
              error={Boolean(errors.name)}
              errmsg={errors.name}
            />

            <TextField
              name="responsibleLastName"
              label="Apellido del Responsable"
              control={control}
              variant="standard"
              rules={RULES.no_special}
              error={Boolean(errors.name)}
              errmsg={errors.name}
            />
          </div> */}

          {/* <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <TextField
              name="responsibleIdentity"
              label="Cédula del Responsable"
              control={control}
              variant="standard"
              rules={RULES.no_special}
              error={Boolean(errors.name)}
              errmsg={errors.name}
            />

            <TextField
              name="responsiblePhone"
              label="Teléfono del Responsable"
              control={control}
              variant="standard"
              rules={RULES.no_special}
              error={Boolean(errors.name)}
              errmsg={errors.name}
            />
          </div> */}
          <TextField
            name="observations"
            label="Observaciones"
            control={control}
            variant="standard"
            multiline={true}
            rules={RULES.required}
            error={Boolean(errors.name)}
            errmsg={errors.name}
            autoFocus
          />



          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>


            <TextField
              name="estudiantes"
              label="Lista de estudiantes"
              control={control}
              type='file'
              variant="standard"
              labelProps={{ shrink: true }}
              error={Boolean(errors.estudiantes)}
              errmsg={errors.estudiantes}
              handleChange={handleFile}
            />
          </div>



          {loadingService
            ? <CircularProgress />
            : <CustomButton
              label={isNewRecord ? 'Crear' : 'Actualizar'}
              typeVariant="outlined"
              typeAction="submit"
              disabled={loadingService}
            />}


        </Stack>
      </form>
    </Stack>
  );
};

export default FormRutas;
