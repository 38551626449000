'use client';
import React, { useMemo, useState, useEffect } from 'react';
import styles from 'styles/Layout.module.scss';
import { Typography } from '@mui/material';
import {
  ActionsButtonsTable,
  CustomButton,
  CardNormal,
  Table,
  CustomModal,
  FormActivities,
  DialogConfirm
} from 'components/index';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import {
  TGenericResponseError,
  TOpenModals,
  TPageState,
  TGenericResponse
} from 'types/index';
import {
  getAllActivitiesServices,
  createActivitiesServices,
  updateActivitiesServices,
  deleteActivitiesServices
} from 'services/activities';
import { useAlert } from 'hooks/useAlert';
import { searchItemLocal } from 'utils/helpers';

/* inicializar los datos para el formulario */
const initialDatarow = {
  id: '',
  name: ''
};
const dataUser = searchItemLocal('dataUser');
const LudicActivity = () => {
  const { openAlert } = useAlert();
  const [openModal, setOpenModal] = useState<TOpenModals>({
    modalCreate: false,
    dialogDelete: false
  });

  const [loadingService, setLoadingService] = useState<boolean>(false);

  const [rowData, setRowData] = useState({
    data: initialDatarow,
    isNewRecord: true
  });

  const [pageState, setPageState] = React.useState<TPageState>({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 50
  });

  const getActivities = async () => {
    try {
      setPageState((prev: TPageState) => ({ ...prev, isLoading: true }));
      const { data } = await getAllActivitiesServices({
        params: {
          skip: pageState?.page * pageState?.pageSize,
          take: pageState?.pageSize,
          deleted: false
        },
        authRequire: true
      });

      setPageState((prev: TPageState) => ({
        ...prev,
        isLoading: false,
        data: data?.data,
        total: data?.total
      }));
    } catch (err: any) {
      openAlert(err.response.data.message, {variant:'error'})
    }
  };

  const handleCreate = () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: initialDatarow, isNewRecord: true });
  };
  const handleEditRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ modalCreate: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const handleDeleteRow = (dataRow: GridCellParams) => () => {
    setOpenModal({ dialogDelete: true });
    setRowData({ data: dataRow.row, isNewRecord: false });
  };

  const onSubmit = async (data: any) => {
    const payload = data
    try {
      setLoadingService(true);
      const id = payload.id;
      delete payload.id;
      if (rowData.isNewRecord) {
        await createActivitiesServices({ payload: payload ,     authRequire: true});
        openAlert('Creacion de actividad realizada exitosamente')
      } else {
        await updateActivitiesServices({ payload: payload, params: id ,authRequire: true});
        openAlert('Modificacion de la actividad realizada exitosamente')
      }
 
    } catch (err) {
      // const message = (err as TGenericResponseError).error as string;
      openAlert('error en en la operación', {variant:'error'})
    } finally {
      setLoadingService(false);
      getActivities();
      setOpenModal({ modalCreate: false });
    }
  };

  const onDeleteRow = async (id: unknown) => {
    try {
      setLoadingService(true);
      (await deleteActivitiesServices({
        params: id as number,authRequire: true
      })) as TGenericResponse;
    } catch (err) {
      openAlert('error en en la operación', {variant:'error'})
    } finally {
      setLoadingService(false);
      getActivities();
      setOpenModal({ dialogDelete: false });
    }
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 20 },
      { field: 'name', headerName: 'Nombre', width: 180, flex: 1 },

      {
        field: 'actions',
        headerName: 'Acciones',
        renderCell: (params) => (
          <ActionsButtonsTable
            handleEditRow={handleEditRow(params)}
            handleDeleteRow={handleDeleteRow(params)}
          />
        ),
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true
      }
    ],
    []
  );

  useEffect(() => {
    getActivities();
  }, [pageState.pageSize, pageState.page]);

  return (
    <section>
      <div className={styles['modules__header']}>
        <Typography className={styles['page-title']} component="h1">
          Actividades Lúdicas
        </Typography>
        <CustomButton
          typeVariant='contained'
          label='Agregar Nuevo'
          onClick={handleCreate}
          className={styles['button-header']}
        />
      </div>
      <CardNormal>
        <Table
          pageState={pageState}
          setPageState={setPageState}
          columns={columns}
        />
      </CardNormal>

      <CustomModal
        modalTitle={
          rowData.isNewRecord ? 'Crear actividad' : 'Actualizar actividad'
        }
        openModal={openModal.modalCreate as boolean}
        setOpenModal={setOpenModal}>
        <FormActivities
          onSubmit={onSubmit}
          rowData={rowData}
          loadingService={loadingService}
        />
      </CustomModal>

      <DialogConfirm
        onDelete={onDeleteRow}
        title='Eliminar Actividad'
        openModal={openModal.dialogDelete}
        setOpenModal={setOpenModal}
        idRow={rowData.data.id}>
        ¿Estas seguro de eliminar esta actividad?
      </DialogConfirm>
    </section>
  );
};

export default LudicActivity;
