import { request } from 'lib/request';

// get all Activities
export function getAllActivitiesServices({ params, authRequire }: any) {
  const { skip, take } = params as any;
  return request('/activities', {
    method: 'get',
    authRequire,
    params: { skip, take, ...params }
  });
}

//create Activities
export function createActivitiesServices({ payload, authRequire }: any) {
  return request('/activities', {
    method: 'post',
    authRequire,
    data: payload
  });
}

export function updateActivitiesServices({
  payload,
  authRequire,
  params
}: any) {
  return request(`/activities/${params}`, {
    method: 'put',
    authRequire,
    data: payload
  });
}

export function deleteActivitiesServices({ authRequire, params }: any) {
  return request(`/activities/${params}`, {
    method: 'put',
    authRequire,
    data: { deleted: true }
  });
}
